import React, { useState } from "react";
import BoosterButton from "../../components/shared/BoosterButton";
import { useMatchStore } from "../../stores/match";

const Booster = ({ match, token }) => {
  const [winBoosters, setWinBoosters] = useState([]);
  const [enableResolve, setEnableResolve] = useState(true);
  const { getMatch, updateMatch } = useMatchStore();

  const handleResolve = async () => {
    const winingOptions = winBoosters.map((option) => {
      return { id: option.id };
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/booster/resolve/${match.id}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ data: winingOptions }),
        }
      );
      if (response.ok) {
        getMatch(match.id);
        setWinBoosters([]);
        setEnableResolve(true);
      }
    } catch (error) {
      console.log("Error resolving market", error);
    }
  };

  const handleSuspend = async (action) => {
    const response = await updateMatch(match.id, token, action);

    if (response.ok) getMatch(match.id);
  };

  return (
    <div>
      <div className="w-full">
        {!match.resolvedBoosters && (
          <div className="flex gap-4">
            <button
              onClick={() => {
                handleSuspend(!match.suspendBoosters);
              }}
              type="button"
              className={`w-full max-w-[400px] bg-transparent py-2 px-3 mb-4 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4] disabled:cursor-not-allowed ${
                match.suspendBoosters &&
                "bg-gradient-to-r from-[#667ce8] via-primary to-[#1e3dce] !text-white"
              }`}
            >
              Suspend
            </button>
            <button
              onClick={() => {
                !enableResolve ? handleResolve() : setEnableResolve(false);
              }}
              type="button"
              className="w-full max-w-[400px] bg-transparent py-2 px-3 mb-4 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4] disabled:cursor-not-allowed"
            >
              {enableResolve ? "Resolve boosters" : "Resolve"}
            </button>
          </div>
        )}
        {!enableResolve && (
          <button
            onClick={() => {
              setEnableResolve(!enableResolve);
              setWinBoosters([]);
            }}
            type="button"
            className="w-full max-w-[400px] bg-transparent py-2 px-3 mb-4 text-s font-medium text-center text-danger border border-danger hover:ring-2 hover:ring-red-600 rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-red-600 focus:scale-115 hover:scale-[101%]"
          >
            Cancel
          </button>
        )}
      </div>
      {match.matchBoosters?.length > 0 &&
        match.matchBoosters.map((hero) => {
          return (
            <div
              key={hero.hero.id}
              className="text-white flex items-center justify-center flex-wrap gap-4 mb-2 max-w-[400px]"
            >
              <div className="flex items-center justify-start flex-1 gap-2">
                <img
                  src={hero.hero.avatarUrl}
                  className="rounded-full w-14 h-12 border-4 border-primary hover:border-blue-400 hover:scale-110"
                  alt=""
                />
                <p className="">{hero.hero.localized_name}</p>
              </div>
              <div
                className={`flex flex-wrap gap-2 justify-start ${
                  hero?.boosters[0].name === "Pick"
                    ? "flex-row"
                    : "flex-row-reverse"
                }`}
              >
                {hero.boosters.map((booster) => {
                  return (
                    <div key={booster.id}>
                      <BoosterButton
                        disabled={enableResolve}
                        setWinBoosters={setWinBoosters}
                        hero={hero}
                        active={winBoosters.some(
                          (item) => item.id === booster.id
                        )}
                        status={booster.status}
                        booster={booster}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Booster;
