import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addNewsSchema } from "../../components/shared/schemas/schemaAddNews";

const EditNews = ({ token, visible, setSuccessEdit, onClose, editNews }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(addNewsSchema),
    defaultValues: {
      title: editNews.title,
      description: editNews.description,
    },
  });

  const handleOnClose = (e) => {
    if (e.target.id === "marketOption") {
      reset();
      onClose();
    }
  };

  const handleEditNews = async (data) => {
    const { title, description } = data;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/news/${editNews.id}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            title: title,
            description: description,
            imageUrl: "",
          }),
        }
      );
      if (response.ok) {
        reset();
        setSuccessEdit(true);
        onClose();
      }
    } catch (error) {
      console.log("Error creating market option", error);
    }
  };

  if (!visible) return null;
  return (
    <div
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px] flex items-center justify-center"
      id="marketOption"
      onClick={handleOnClose}
    >
      <div className="bg-body p-8 rounded-2xl shadow-2xl w-full max-w-[400px] mx-4">
        <h1 className="font-semibold text-center text-2xl text-white mb-5 border-b border-gray-200 border-opacity-10 pb-4">
          Edit News
        </h1>

        <div className="flex flex-col">
          <form>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-textPrimary">
                Title
              </label>
              <input
                type="text"
                className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                placeholder="Enter title"
                {...register("title")}
              />
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.title?.message}
              </p>
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-textPrimary">
                Description
              </label>
              <textarea
                type="text"
                className="h-fit min-h-[168px] max-h-[300px] border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                placeholder="Enter description"
                {...register("description")}
              />
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.description?.message}
              </p>
            </div>
          </form>
        </div>
        <div className="text-center mt-4">
          <button
            onClick={handleSubmit(handleEditNews)}
            type="button"
            className="w-full bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
          >
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditNews;
