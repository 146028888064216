import create from "zustand";

export const useIntervalStore = create((set) => ({
  intervals: null,
  loading: true,
  matches: null,
  getIntervals: async (token) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/interval`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        set({
          intervals: data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
}));
