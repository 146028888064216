import { useState } from "react";
import MarketDetails from "./MarketDetails";

const Games = ({ timeId, defaultGame, gameId }) => {
  const [gameTab, setGameTab] = useState(defaultGame);
  const games = ["Pre-Game", "Game 1", "Game 2", "Game 3", "Game 4", "Game 5"];

  return (
    <div className="text-white w-full">
      <div className="flex items-center mb-2">
        {games.map((game, index) => {
          return (
            <button
              key={index}
              onClick={() => setGameTab(index)}
              className={`min-w-[80px] py-2 px-3 mr-4 text-s font-medium text-center ${
                gameTab === index
                  ? "border-b-2 border-primary text-white"
                  : "border-b-2 border-transparent text-primary"
              } hover:text-white`}
            >
              {game}
            </button>
          );
        })}
      </div>
      <div className="max-h-[460px] overflow-auto">
        <MarketDetails gameTab={gameTab} timeId={timeId} gameId={gameId} />
      </div>
    </div>
  );
};

export default Games;
