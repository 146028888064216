import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import MultipleSelect from "../../components/shared/MultipleSelect";
import { resolveMarketSchema } from "../../components/shared/schemas/schemaMarketResolve";
import { useMatchStore } from "../../stores/match";

const ResolveMarket = ({ onClose, visible, resolveData, token }) => {
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(resolveMarketSchema),
  });
  const [boosterError, setBoosterError] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { getMatch } = useMatchStore();
  const handleOnClose = (e) => {
    if (e.target.id === "marketSuspend") {
      reset();
      setSelectedOptions([]);
      setBoosterError("");
      onClose();
    }
  };
  const onSubmit = async () => {
    const winingOptions = selectedOptions.map((option) => {
      return { id: option.id };
    });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market-option/resolve`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ data: winingOptions }),
        }
      );
      const data = await response.json();
      if (data.success) {
        getMatch(resolveData.matchId);
        setSelectedOptions([]);
        onClose();
        setBoosterError("");
      } else {
        setBoosterError("Please resolve boosters first");
      }
    } catch (error) {
      console.log("Error resolving market", error);
    }
  };

  if (!visible) return null;
  return (
    <div
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px] flex items-center justify-center"
      id="marketSuspend"
      onClick={handleOnClose}
    >
      <div className="bg-body p-8 rounded-2xl shadow-2xl w-full max-w-[400px] mx-4">
        <h1 className="font-semibold text-center text-2xl text-white mb-5 border-b border-gray-200 border-opacity-10 pb-4">
          Resolve Market
        </h1>
        <div className="flex flex-col">
          <form>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-textPrimary">
                Win options
              </label>
              <Controller
                control={control}
                name="marketOptions"
                error={errors?.type}
                render={({ field: { onChange } }) => (
                  <MultipleSelect
                    selected={selectedOptions}
                    setSelected={setSelectedOptions}
                    onChange={() => onChange(selectedOptions)}
                    options={resolveData?.market?.marketOptions}
                    type="marketOptions"
                    z={20}
                  />
                )}
              />
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.marketOptions?.message}
              </p>
              <p className="font-normal text-center text-[11px] leading-5 text-danger ml-2 mt-4">
                {boosterError !== "" && boosterError}
              </p>
            </div>
          </form>
        </div>

        <div className="text-center mt-4">
          <button
            onClick={handleSubmit(onSubmit)}
            type="button"
            className="w-full bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
          >
            Resolve
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResolveMarket;
