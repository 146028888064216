import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../stores/auth";

const style = {
  wrapper: "flex h-screen w-full overflow-hidden",
  contentWrapper:
    "rounded-tl-xl h-[calc(100%-103px)] p-6 bg-[#07080b] overflow-y-scroll",
  rightSide: "flex flex-col w-full overflow-hidden bg-body",
};

const Layout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useAuthStore();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/matches");
    }
    //eslint-disable-next-line
  }, [pathname, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
    //eslint-disable-next-line
  }, [isLoggedIn, navigate]);

  return (
    <div className={style.wrapper}>
      <Sidebar />
      <div className={style.rightSide}>
        <Header />
        <div className={style.contentWrapper}>{<Outlet />}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
