import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CreateMarket from "./CreateMarket";
import { useAuthStore } from "../../stores/auth";
import { useMatchStore } from "../../stores/match";
import Booster from "./Booster";
import GameController from "./game-controller";
import AutomatizeMarkets from "./AutomatizeMarkets";

const MatchDetails = () => {
  const { token } = useAuthStore();
  const { id } = useParams();
  const { match, getMatch, clearMatchDetails } = useMatchStore();

  useEffect(() => {
    if (id) {
      getMatch(id);
    }
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    return () => {
      clearMatchDetails();
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col gap-8">
      {match && (
        <>
          <div className="flex gap-8 flex-wrap pb-8 border-b border-primary">
            <div className="flex flex-col px-6 py-4 text-white flex-1  bg-body rounded-xl">
              <p className="text-sm">
                {match?.game?.shortName.charAt(0).toUpperCase() +
                  match?.game?.shortName.slice(1)}{" "}
                - {match.tournament?.name}
              </p>
              <div className="overflow-scroll max-h-[200px] w-full max-w-[600px] rounded-lg scrollbar-hide my-4">
                <table className="w-full mt-8 overflow-scroll">
                  <thead className=" text-sm text-gray-400">
                    <tr>
                      <th scope="col" className="mx-2"></th>
                      <th scope="col" className="mx-2">
                        Result
                      </th>
                      <th scope="col" className="mx-2">
                        Game 1
                      </th>
                      <th scope="col" className="mx-2">
                        Game 2
                      </th>
                      <th scope="col" className="mx-2">
                        Game 3
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {match?.matchTeams?.map((team) => {
                      return (
                        <tr key={team.team.id}>
                          <td className="pl-4">
                            <p className="text-lg">
                              <b>{team.team.name}</b>
                            </p>
                          </td>
                          <td>
                            <p className="text-lg text-center">
                              <b>1</b>
                            </p>
                          </td>
                          <td>
                            <p className="text-lg text-center">
                              <b>25</b>
                            </p>
                          </td>
                          <td>
                            <p className="text-lg text-center">
                              <b>5</b>
                            </p>
                          </td>
                          <td>
                            <p className="text-lg text-center">
                              <b></b>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <GameController matchId={id} />

          <div className="flex flex-wrap gap-8">
            {match?.matchBoosters?.length > 0 && (
              <Booster token={token} match={match} />
            )}
            <CreateMarket token={token} match={match} />
            <AutomatizeMarkets token={token} matchId={match.id} />
          </div>
        </>
      )}
    </div>
  );
};

export default MatchDetails;
