import React from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";

const MarketDetail = ({
  market,
  matchId,
  setAreYouSureVisible,
  setDeleteMarket,
  setMarketId,
  setMarketOptionEdit,
  setResolveMarket,
  setResolveMarketVisible,
  setSuspendMarket,
  setEditOptionVisible,
  setAddMarketOptionVisible,
  handleHideMarket,
  handleDeleteMarketOption,
}) => {
  const getMarketId = (id) => {
    setMarketId(id);
  };

  const getMarketOption = (marketOption) => {
    setMarketOptionEdit(marketOption);
  };

  return (
    <div
      key={market.id}
      className="flex-1 w-[100%] max-w-[520px] bg-body p-6 rounded-2xl h-fit shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
    >
      <div
        key={market.id}
        className="text-gray-300 bg-[#primary] mb-2 border border-textPrimary flex flex-col p-4 rounded-2xl"
      >
        <p className="text-primary text-xl font-semibold text-center break-all">
          {market.name} - {market.eventType}
        </p>
        <div className="self-center border-red-200 flex flex-wrap gap-4 mt-4 max-w-[80%]">
          <button
            onClick={() => {
              handleHideMarket(market.id, !market.hidden);
            }}
            type="button"
            className={`flex-1 bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary ${
              market.hidden &&
              "bg-gradient-to-r from-[#667ce8] via-primary to-[#1e3dce] !text-white"
            } rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]`}
          >
            {market?.hidden ? "Unhide" : "Hide"}
          </button>
          <button
            onClick={() => {
              setSuspendMarket({
                id: market.id,
                action: !market?.suspended,
                actionString: market.suspended ? "unsuspend" : "suspend",
              });
              setAreYouSureVisible(true);
            }}
            type="button"
            className={`flex-1 bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary ${
              market.suspended &&
              "bg-gradient-to-r from-[#667ce8] via-primary to-[#1e3dce] !text-white"
            } rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]`}
          >
            {market.suspended ? "Unsuspend" : "Suspend"}
          </button>
          <button
            onClick={() => {
              setResolveMarket({
                market: market,
                matchId: matchId,
              });
              setResolveMarketVisible(true);
            }}
            type="button"
            className={`flex-1 bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary ${
              market.resolved &&
              "bg-gradient-to-r from-[#667ce8] via-primary to-[#1e3dce] !text-white"
            } rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4] disabled:cursor-not-allowed`}
          >
            {market.resolved ? "Resolved" : "Resolve"}
          </button>
          <button
            onClick={() => {
              setDeleteMarket({
                id: market.id,
                actionString: "delete",
              });
              setAreYouSureVisible(true);
            }}
            type="button"
            className="flex-1 bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
          >
            Delete
          </button>
        </div>
        <div className="overflow-scroll max-h-[600px] w-full self-center rounded-lg scrollbar-hide my-4">
          <table className="text-sm text-left text-textPrimary w-full overflow-scroll">
            <thead className="text-xs text-textPrimary uppercase bg-[#111419]">
              <tr>
                <th scope="col" className="py-2 px-6">
                  Name
                </th>
                <th scope="col" className="py-2 px-6">
                  Coefficient
                </th>
                <th scope="col" className="py-2 px-6">
                  Status
                </th>
                <th scope="col" className="py-2 px-6">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {market?.marketOptions.length > 0 &&
                market?.marketOptions.map((marketOption) => {
                  return (
                    <tr
                      key={marketOption.id}
                      className="bg-body hover:rounded-3xl hover:bg-[#242934]"
                    >
                      <th
                        scope="row"
                        className="py-3 px-6 font-medium text-white "
                      >
                        {marketOption.name}
                      </th>
                      <td className="py-3 px-6">{marketOption.coefficient}</td>
                      <td
                        className={`py-3 px-6 ${
                          (marketOption.status === 1 ||
                            marketOption.status === 2) &&
                          "text-white"
                        }`}
                      >
                        {marketOption.status === 0
                          ? "Ongoing"
                          : marketOption.status === 1
                          ? "Win"
                          : "Lose"}
                      </td>
                      <td className="py-3 px-6">
                        <div className="flex">
                          <AiOutlineEdit
                            onClick={() => {
                              getMarketId(market.id);
                              getMarketOption(marketOption);
                              setEditOptionVisible(true);
                            }}
                            className="text-lg hover:cursor-pointer hover:fill-primary flex-1"
                          />
                          <AiOutlineDelete
                            onClick={() => {
                              handleDeleteMarketOption(marketOption.id);
                            }}
                            className="text-lg hover:cursor-pointer hover:fill-primary flex-1"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {market?.marketOptions.length === 0 && (
            <p className="mt-4 text-lg text-textPrimary text-center">
              There are no market options
            </p>
          )}
        </div>

        <button
          type="button"
          className="w-full max-w-[60%] self-center py-2 px-3 text-s font-medium text-center text-white bg-primary rounded-lg hover:bg-[#3a57e2] hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#667ce8]"
          onClick={() => {
            getMarketId(market.id);
            setAddMarketOptionVisible(true);
          }}
        >
          Add Market Option
        </button>
      </div>
    </div>
  );
};

export default MarketDetail;
