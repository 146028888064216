import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

const MultipleSelect = ({
  selected,
  setSelected,
  onChange,
  options,
  type,
  z,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange();
    }
    //eslint-disable-next-line
  }, [selected]);

  return (
    <div className="relative w-full font-sm h-[40px]">
      <div
        onClick={() => setOpen(!open)}
        className={`bg-gray-800 text-white text-[14px] w-full p-[10px] flex flex-row items-center justify-between border border-gray-600 hover:border-primary cursor-pointer rounded-lg ${
          !selected.name && "text-gray-700"
        }`}
      >
        <div className="flex gap-1 text-white">
          {selected.length > 0 ? (
            selected.map((item, index) => {
              return (
                <p key={item.id}>
                  {item.name}
                  {index === selected.length - 1 ? "" : ","}
                </p>
              );
            })
          ) : (
            <p className="text-gray-700">select win {type}</p>
          )}
        </div>
        <BiChevronDown
          size={20}
          className={`${open && "rotate-180 text-red-900"} text-gray-600 `}
        />
      </div>
      <ul
        className={`bg-gray-800 mt-2 overflow-y-auto scrollbar-hide rounded-lg cursor-pointer shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] ${
          open ? "max-h-60" : "max-h-0"
        } relative z-${z}`}
      >
        {options?.map((option) => (
          <li
            key={option?.id}
            className={`p-2 text-sm text-textPrimary hover:bg-primary hover:text-white m-1 rounded-lg
            ${
              selected.some((item) => item.id === option.id) &&
              "bg-primary text-[#FFFFFF] rounded-lg"
            }
            ${
              option?.name?.toLowerCase().startsWith(inputValue)
                ? "block"
                : "hidden"
            }`}
            onClick={() => {
              setSelected((prev) => {
                if (prev.some((item) => item.id === option.id)) {
                  return prev.filter((item) => item.id !== option.id);
                } else return [...prev, option];
              });
              setOpen(false);
              setInputValue("");
            }}
          >
            {option?.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultipleSelect;
