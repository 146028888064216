import React, { useEffect, useMemo, useState } from "react";
import { useMatchStore } from "../../../stores/match";
import AddMarketOption from "../AddMarketOption";
import MarketOptionEdit from "../MarketOptionEdit";
import ResolveMarket from "../ResolveMarket";
import AreYouSureModal from "../../../components/shared/AreYouSureModal";
import { useAuthStore } from "../../../stores/auth";
import MarketDetail from "./MarketDetail";
import { useIntervalStore } from "../../../stores/intervals";

const MarketDetails = ({ gameTab, timeId, gameId }) => {
  const { token } = useAuthStore();
  const { match, getMatch } = useMatchStore();
  const { getIntervals, intervals } = useIntervalStore();

  const [marketOptionEdit, setMarketOptionEdit] = useState(null);
  const [marketId, setMarketId] = useState(null);
  const [addMarketOptionVisible, setAddMarketOptionVisible] = useState(false);
  const [editOptionVisible, setEditOptionVisible] = useState(false);
  const [areYouSureVisible, setAreYouSureVisible] = useState(false);
  const [resolveMarketVisible, setResolveMarketVisible] = useState(false);
  const [suspendMarket, setSuspendMarket] = useState({});
  const [deleteMarket, setDeleteMarket] = useState({});
  const [resolveMarket, setResolveMarket] = useState({});

  const setHandlers = {
    setAreYouSureVisible: setAreYouSureVisible,
    setDeleteMarket: setDeleteMarket,
    setMarketId: setMarketId,
    setMarketOptionEdit: setMarketOptionEdit,
    setResolveMarket: setResolveMarket,
    setResolveMarketVisible: setResolveMarketVisible,
    setSuspendMarket: setSuspendMarket,
    setEditOptionVisible: setEditOptionVisible,
    setAddMarketOptionVisible: setAddMarketOptionVisible,
  };

  const handleSuspendMarketClose = () => {
    setSuspendMarket({});
    setAreYouSureVisible(false);
  };

  const handleDeleteMarketClose = () => {
    setDeleteMarket({});
    setAreYouSureVisible(false);
  };

  const handleResolveMarketClose = () => {
    setResolveMarket({});
    setResolveMarketVisible(false);
  };

  const handleEditOptionClose = () => {
    setMarketOptionEdit(null);
    setEditOptionVisible(false);
  };

  const handleAddMarketOptionClose = () => {
    setAddMarketOptionVisible(false);
  };

  const handleHideMarket = async (id, action) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            hidden: action,
          }),
        }
      );

      if (response.ok) {
        getMatch(match.id);
        handleSuspendMarketClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMarketOption = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market-option/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        getMatch(match.id);
      }
    } catch (error) {
      console.log("Error deleting market option: ", error);
    }
  };

  const handleDeleteMarket = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market/${data.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        handleDeleteMarketClose();
        getMatch(match.id);
      }
    } catch (error) {
      console.log("Error deleting market: ", error);
    }
  };

  const handleSuspendMarket = async (data) => {
    const { id, action } = data;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            suspended: action,
          }),
        }
      );

      if (response.ok) {
        getMatch(match.id);
        handleSuspendMarketClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlers = {
    handleHideMarket: handleHideMarket,
    handleDeleteMarketOption: handleDeleteMarketOption,
  };

  const marketsDisplay = useMemo(() => {
    let computedMarkets = match.markets;
    if (!match.markets) computedMarkets = [];

    return computedMarkets
      .filter((market) => market.gameId === gameTab)
      .sort((a, b) => (b.intervalId > a.intervalId ? -1 : 1));
  }, [gameTab, match]);

  useEffect(() => {
    getIntervals(token);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex-1 w-[100%] flex flex-col gap-4">
        {marketsDisplay.length === 0 && (
          <div className="w-full h-56 text-center border p-8 border-body rounded-xl">
            <p className="text-textPrimary text-lg pt-2">
              There are no markets for{" "}
              {gameTab === 0 ? "pre-game" : `game ${gameTab}`}.
            </p>
            <p className="text-gray-700">
              To add, go to Create or Automatize market options.
            </p>
          </div>
        )}
        {gameTab === 0 ? (
          <>
            {marketsDisplay.length > 0 && (
              <div className="w-[100%] flex flex-wrap gap-4 bg-gray-900 p-8 rounded-xl">
                {marketsDisplay.map((market) => {
                  return (
                    <MarketDetail
                      key={market.id}
                      market={market}
                      matchId={match.id}
                      {...setHandlers}
                      {...handlers}
                    />
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <>
            {intervals?.length > 0 &&
              marketsDisplay.length > 0 &&
              intervals?.slice(1).map((interval, index) => {
                return (
                  <div
                    key={interval.id}
                    className={`w-[100%] flex flex-col gap-4 bg-gray-900 p-8 rounded-xl ${
                      marketsDisplay[0]?.gameId === gameId &&
                      interval.id === timeId &&
                      "bg-[#464555]"
                    }`}
                  >
                    {index === 0 ? (
                      <p
                        className={`text-textPrimary ${
                          marketsDisplay[0]?.gameId === gameId &&
                          interval.id === timeId &&
                          "text-white"
                        }`}
                      >
                        From{" "}
                        <b
                          className={`${
                            marketsDisplay[0]?.gameId === gameId &&
                            interval.id === timeId
                              ? "text-[#BFA975]"
                              : "text-white"
                          }`}
                        >
                          {interval.name.slice(0, 6)}
                        </b>{" "}
                        To{" "}
                        <b
                          className={`${
                            marketsDisplay[0]?.gameId === gameId &&
                            interval.id === timeId
                              ? "text-[#BFA975]"
                              : "text-white"
                          }`}
                        >
                          {interval.name.slice(7)}
                        </b>
                      </p>
                    ) : interval.name === "60-END" ? (
                      <p
                        className={`text-textPrimary ${
                          marketsDisplay[0]?.gameId === gameId &&
                          interval.id === timeId &&
                          "text-white"
                        }`}
                      >
                        From{" "}
                        <b
                          className={`${
                            marketsDisplay[0]?.gameId === gameId &&
                            interval.id === timeId
                              ? "text-[#BFA975]"
                              : "text-white"
                          }`}
                        >
                          {interval.name.slice(0, 2)}
                        </b>{" "}
                        To{" "}
                        <b
                          className={`${
                            marketsDisplay[0]?.gameId === gameId &&
                            interval.id === timeId
                              ? "text-[#BFA975]"
                              : "text-white"
                          }`}
                        >
                          {interval.name.slice(3)}
                        </b>
                      </p>
                    ) : (
                      <p className="text-textPrimary">
                        From{" "}
                        <b
                          className={`${
                            marketsDisplay[0]?.gameId === gameId &&
                            interval.id === timeId
                              ? "text-[#BFA975]"
                              : "text-white"
                          }`}
                        >
                          {interval.name.slice(0, 5)}
                        </b>{" "}
                        To{" "}
                        <b
                          className={`${
                            marketsDisplay[0]?.gameId === gameId &&
                            interval.id === timeId
                              ? "text-[#BFA975]"
                              : "text-white"
                          }`}
                        >
                          {interval.name.slice(6)}
                        </b>
                      </p>
                    )}
                    <div className="flex flex-wrap gap-4">
                      {marketsDisplay
                        .filter((market) => market.intervalId === interval.id)
                        .map((market) => {
                          return (
                            <MarketDetail
                              key={market.id}
                              market={market}
                              matchId={match.id}
                              {...setHandlers}
                              {...handlers}
                            />
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
      {marketOptionEdit && (
        <MarketOptionEdit
          token={token}
          onClose={handleEditOptionClose}
          visible={editOptionVisible}
          marketId={marketId}
          marketOption={marketOptionEdit}
          matchId={match.id}
        />
      )}
      {addMarketOptionVisible && (
        <AddMarketOption
          token={token}
          onClose={handleAddMarketOptionClose}
          visible={addMarketOptionVisible}
          marketId={marketId}
          matchId={match.id}
        />
      )}
      {areYouSureVisible && suspendMarket.id && (
        <AreYouSureModal
          onClose={handleSuspendMarketClose}
          visible={areYouSureVisible}
          handleAction={handleSuspendMarket}
          data={suspendMarket}
        />
      )}
      {areYouSureVisible && deleteMarket.id && (
        <AreYouSureModal
          onClose={handleDeleteMarketClose}
          visible={areYouSureVisible}
          handleAction={handleDeleteMarket}
          data={deleteMarket}
        />
      )}
      {resolveMarketVisible && (
        <ResolveMarket
          onClose={handleResolveMarketClose}
          visible={resolveMarketVisible}
          resolveData={resolveMarket}
          token={token}
        />
      )}
    </>
  );
};

export default MarketDetails;
