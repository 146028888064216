import * as yup from "yup";

export const marketSchema = yup.object().shape({
  gameId: yup.object().shape({
    name: yup.string().required("Please enter a game"),
  }),
  interval: yup.object().shape({
    name: yup.string().required("Please enter an interval"),
  }),
  type: yup.object().shape({
    name: yup.string().required("Please enter a type"),
  }),
  marketOptions: yup
    .array(
      yup.object().shape({
        name: yup.string().required("Please enter a name"),
        coefficient: yup.string().required("Please enter a coefficient"),
      })
    )
    .required("Please add at least two market options")
    .min(2, "Please add at least two market options"),
  market: yup.object().shape({
    name: yup.string().required("Please enter a market"),
  }),
});
