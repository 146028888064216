import { useEffect, useState } from "react";
import { useAuthStore } from "../../stores/auth";
import AddNews from "./AddNews";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import EditNews from "./EditNews";
import AreYouSureModal from "../../components/shared/AreYouSureModal";

const News = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [areYouSureVisible, setAreYouSureVisible] = useState(false);
  const [editNews, setEditNews] = useState(false);
  const [deleteNews, setDeleteNews] = useState({});
  const [successAdd, setSuccessAdd] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);
  const { token } = useAuthStore();

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleCloseEditModal = () => {
    setModalEditVisible(false);
  };

  const handleDeleteNewsClose = () => {
    setDeleteNews({});
    setAreYouSureVisible(false);
  };

  const getNews = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/news`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.ok) {
        setNews(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Get news:", error);
    }
  };

  const handleDeleteNews = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/news/${data.id}`,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        handleDeleteNewsClose();
        getNews();
      }
    } catch (error) {
      console.log("Error deleting news: ", error);
    }
  };

  useEffect(() => {
    getNews();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (successAdd || successEdit) {
      getNews();
      setSuccessAdd(false);
      setSuccessEdit(false);
    }
  }, [successAdd, successEdit]);

  return (
    <div className="max-w-[800px]">
      <div className="flex justify-between">
        <h4 className="text-2xl text-white">NEWS</h4>
        <button
          onClick={() => {
            setModalVisible(true);
          }}
          type="button"
          className="w-full max-w-[40%] bg-transparent mb-4 py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
        >
          Add
        </button>
      </div>
      <div className="overflow-scroll max-h-[600px] rounded-lg scrollbar-hide my-8">
        <table className="text-sm text-left text-textPrimary w-full overflow-scroll">
          <thead className="text-xs text-textPrimary uppercase bg-[#111419]">
            <tr>
              <th scope="col" className="py-3 px-6">
                Title
              </th>
              <th scope="col" className="flex gap-2 items-center py-3 px-6">
                Description
              </th>
              <th scope="col" className="py-3 px-6">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {news?.length > 0 &&
              news.map((item) => {
                return (
                  <tr
                    className="bg-body hover:rounded-3xl hover:bg-[#242934] cursor-pointer"
                    key={item.id}
                  >
                    <th className="py-4 px-6 text-white">{item.title}</th>

                    <td className="py-4 px-6">{item.description}</td>
                    <td className="py-3 px-6">
                      <div className="flex">
                        <AiOutlineEdit
                          onClick={() => {
                            setEditNews(item);
                            setModalEditVisible(true);
                          }}
                          className="text-lg hover:cursor-pointer hover:fill-primary flex-1"
                        />
                        <AiOutlineDelete
                          onClick={() => {
                            setDeleteNews({
                              id: item.id,
                              actionString: "delete",
                            });
                            setAreYouSureVisible(true);
                          }}
                          className="text-lg hover:cursor-pointer hover:fill-primary flex-1"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {news?.length === 0 && !loading && (
          <p className="mt-4 text-lg text-textPrimary text-center">
            There are currently no news
          </p>
        )}
      </div>
      {modalVisible && token && (
        <AddNews
          onClose={handleCloseModal}
          visible={modalVisible}
          token={token}
          setSuccessAdd={setSuccessAdd}
        />
      )}
      {modalEditVisible && token && (
        <EditNews
          onClose={handleCloseEditModal}
          visible={modalEditVisible}
          token={token}
          editNews={editNews}
          setSuccessEdit={setSuccessEdit}
        />
      )}
      {areYouSureVisible && deleteNews.id && (
        <AreYouSureModal
          onClose={handleDeleteNewsClose}
          visible={areYouSureVisible}
          handleAction={handleDeleteNews}
          data={deleteNews}
        />
      )}
    </div>
  );
};

export default News;
