import React, { useEffect, useState, useMemo } from "react";
import { BiChevronDown } from "react-icons/bi";

const Select = ({
  selected,
  setSelected,
  onChange,
  options,
  type,
  disabled,
  z,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  const getSelected = useMemo(() => {
    if (selected.name && selected.name.length > 25) {
      return selected.name.substring(0, 25) + "...";
    } else if (selected.name) {
      return selected.name;
    } else {
      return `Select ${type}`;
    }
  }, [selected, type]);

  useEffect(() => {
    if (onChange) {
      onChange();
    }
    //eslint-disable-next-line
  }, [selected]);
  return (
    <div className="w-full font-sm h-[40px]">
      <div
        onClick={() => (!disabled ? setOpen(!open) : null)}
        className={`bg-gray-800 text-white text-[14px] w-full p-[10px] flex flex-row items-center justify-between border border-gray-600 hover:border-primary cursor-pointer rounded-lg ${
          !selected.name && "text-gray-700"
        }`}
      >
        {getSelected}
        <BiChevronDown
          size={20}
          className={`${open && "rotate-180 text-red-900"} text-gray-600 `}
        />
      </div>
      <ul
        className={`bg-gray-800 mt-2 overflow-y-auto scrollbar-hide rounded-lg cursor-pointer shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)] ${
          open ? "max-h-60" : "max-h-0"
        } relative ${z}`}
      >
        {options?.map((option) => (
          <li
            key={option?.id}
            className={`p-2 text-sm text-textPrimary hover:bg-primary hover:text-white m-1 rounded-lg
            ${
              option?.name?.toLowerCase() === selected?.name?.toLowerCase() &&
              "bg-primary text-[#FFFFFF] rounded-lg"
            }
            ${
              option?.name?.toLowerCase().startsWith(inputValue)
                ? "block"
                : "hidden"
            }`}
            onClick={() => {
              if (
                option?.name?.toLowerCase() !== selected?.name?.toLowerCase()
              ) {
                setSelected(option);
                setOpen(false);
                setInputValue("");
              }
            }}
          >
            {option?.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Select;
