import React, { useEffect, useState } from "react";
import Select from "../../components/shared/Select";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { marketSchema } from "../../components/shared/schemas/schemaMarket";
import { useMatchStore } from "../../stores/match";
import { useIntervalStore } from "../../stores/intervals";

const gameIdOptions = [
  {
    id: 0,
    name: "PreGame",
  },
  {
    id: 1,
    name: "Game 1",
  },
  {
    id: 2,
    name: "Game 2",
  },
  {
    id: 3,
    name: "Game 3",
  },
  {
    id: 4,
    name: "Game 4",
  },
  {
    id: 5,
    name: "Game 5",
  },
];

const typeOptions = [
  {
    id: 1,
    name: "Ancient",
  },
  {
    id: 2,
    name: "Players",
  },
  {
    id: 3,
    name: "Towers",
  },
];

const ancientOptions = [
  {
    id: 1,
    name: "Who will win the Series?",
  },
  {
    id: 2,
    name: "Who will win Game 1 of the Series?",
  },
  {
    id: 3,
    name: "Who will win Game 2 of the Series?",
  },
  {
    id: 4,
    name: "Who will win Game 3 of the Series?",
  },
  {
    id: 5,
    name: "What will be the correct score of the Series?",
  },
];

const gameScoreOptions = [
  {
    id: 1,
    name: "2:0",
  },
  {
    id: 2,
    name: "2:1",
  },
  {
    id: 3,
    name: "1:2",
  },
  {
    id: 4,
    name: "0:2",
  },
];

const CreateMarket = ({ token, match }) => {
  const [selectedType, setSelectedType] = useState({ id: null, name: "" });
  const [selectedGameId, setSelectedGameId] = useState({ id: null, name: "" });
  const [selectedInterval, setSelectedInterval] = useState({
    id: null,
    name: "",
  });
  const [selectedMarket, setSelectedMarket] = useState({ id: null, name: "" });
  const [successCreate, setSuccessCreate] = useState(false);
  const { getMatch } = useMatchStore();
  const { getIntervals, intervals } = useIntervalStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(marketSchema),
    defaultValues: {
      marketOptions: [{ coefficient: "", name: "" }],
      market: { id: null, name: "" },
    },
  });
  const { name } = register("market", {
    id: null,
    name: "",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "marketOptions",
  });

  const onSubmit = async (data) => {
    const { market, marketOptions, type, gameId, hidden } = data;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market/create`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            matchId: match.id,
            eventType: type.name,
            name: market.name,
            gameId: gameId.id,
            intervalId: selectedInterval.id,
            hidden: hidden,
          }),
        }
      );
      let data = await response.json();
      if (response.ok) {
        handleCreateMarketOptions(marketOptions, data.id);
      }
    } catch (error) {
      console.log("Error creating market", error);
    }
  };

  const handleCreateMarketOptions = async (marketOptions, id) => {
    try {
      marketOptions = marketOptions.map((marketOption) => {
        return (marketOption = {
          ...marketOption,
          coefficient: parseFloat(marketOption.coefficient),
          marketId: parseInt(id),
        });
      });
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/market-option/create`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: marketOptions,
          }),
        }
      );

      if (response.ok) {
        setSuccessCreate(true);
      }
    } catch (error) {
      console.log("Error creating market option", error);
    }
  };

  useEffect(() => {
    fields.map((index) => {
      return remove(index);
    });
    setSelectedMarket({ id: null, name: "" });

    //eslint-disable-next-line
  }, [selectedType]);

  useEffect(() => {
    if (selectedMarket && selectedMarket.id) {
      fields.map((index) => {
        return remove(index);
      });
    }
    if (selectedMarket.id && selectedType.name === "Ancient") {
      if (selectedMarket.id === 5) {
        gameScoreOptions.map((option) => {
          return append({ coefficient: "", name: option.name });
        });
      } else {
        match?.matchTeams?.map((team) => {
          return append({ coefficient: "", name: team.team.name });
        });
      }
    }
    //eslint-disable-next-line
  }, [selectedMarket]);

  useEffect(() => {
    if (successCreate) {
      reset();
      setSelectedType({ id: null, name: "" });
      setSelectedMarket({ id: null, name: "" });
      setSelectedGameId({ id: null, name: "" });
      getMatch(match.id);
      setSuccessCreate(false);
    }
    //eslint-disable-next-line
  }, [successCreate]);

  useEffect(() => {
    getIntervals(token);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedGameId.id === 0) {
      setSelectedInterval({ id: -1, name: "PREGAME" });
    } else {
      setSelectedInterval({ id: null, name: "" });
    }
  }, [selectedGameId]);

  return (
    <div className=" bg-body p-8 rounded-2xl shadow-2xl w-full max-w-[460px] h-fit">
      <h1 className="font-semibold text-center text-2xl text-white mb-5 border-b border-gray-200 border-opacity-10 pb-4">
        Create Market
      </h1>

      <div className="flex flex-col">
        <form>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-textPrimary">
              Game
            </label>
            <Controller
              control={control}
              name="gameId"
              error={errors?.type}
              render={({ field: { onChange } }) => (
                <Select
                  selected={selectedGameId}
                  setSelected={setSelectedGameId}
                  onChange={() => onChange(selectedGameId)}
                  options={gameIdOptions}
                  type="game"
                  z="z-30"
                />
              )}
            />
            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
              {errors.gameId?.name?.message}
            </p>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-textPrimary">
              Interval
            </label>
            {intervals && (
              <Controller
                control={control}
                name="interval"
                error={errors?.type}
                render={({ field: { onChange } }) => (
                  <Select
                    disabled={selectedGameId.id === 0}
                    selected={selectedInterval}
                    setSelected={setSelectedInterval}
                    onChange={() => onChange(selectedInterval)}
                    options={intervals.slice(1)}
                    type="interval"
                    z="z-20"
                  />
                )}
              />
            )}
            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
              {errors.interval?.name?.message}
            </p>
          </div>

          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-textPrimary">
              Type
            </label>
            <Controller
              control={control}
              name="type"
              error={errors?.type}
              render={({ field: { onChange } }) => (
                <Select
                  selected={selectedType}
                  setSelected={setSelectedType}
                  onChange={() => onChange(selectedType)}
                  options={typeOptions}
                  type="type"
                  z="z-10"
                />
              )}
            />
            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
              {errors.type?.name?.message}
            </p>
          </div>

          {selectedType.name === "Ancient" && (
            <>
              <label className="block mb-2 text-sm font-medium text-textPrimary">
                Market
              </label>
              <Controller
                control={control}
                name={name}
                error={errors?.type}
                render={({ field: { onChange } }) => (
                  <Select
                    selected={selectedMarket}
                    setSelected={setSelectedMarket}
                    onChange={() => onChange(selectedMarket)}
                    options={ancientOptions}
                    type={name}
                    z="z-10"
                  />
                )}
              />
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.market?.name?.message}
              </p>
              {selectedMarket?.id && selectedMarket.id === 5 ? (
                <>
                  <div>
                    {fields.map((item, index) => (
                      <div key={item.id} className="mb-4 first-of-type:mt-4">
                        <label className="block mb-2 text-sm font-medium text-textPrimary">
                          Option: <b>{item.name}</b>
                        </label>
                        <input
                          type="number"
                          className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                          placeholder="Enter coefficient"
                          {...register(`marketOptions.${index}.coefficient`)}
                        />
                        <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                          {errors?.marketOptions?.[index]?.coefficient?.message}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    {fields.map((item, index) => (
                      <div key={item.id} className="py-2">
                        <label className="block mb-2 text-sm font-medium text-textPrimary">
                          Option: <b>{item.name}</b>
                        </label>
                        <input
                          type="number"
                          className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                          placeholder="Enter coefficient"
                          {...register(`marketOptions.${index}.coefficient`)}
                        />
                        <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                          {errors?.marketOptions?.[index]?.coefficient?.message}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}

          {(selectedType.name === "Players" ||
            selectedType.name === "Towers") && (
            <>
              <label className="block mb-2 text-sm font-medium text-textPrimary">
                Market
              </label>
              <input
                type="text"
                className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                placeholder="Enter Name"
                onChange={(e) =>
                  setSelectedMarket({ id: 1, name: e.target.value })
                }
                value={selectedMarket.name}
              />
              <div className="hidden">
                <Controller
                  control={control}
                  name={name}
                  error={errors?.type}
                  render={({ field: { onChange } }) => (
                    <Select
                      selected={selectedMarket}
                      setSelected={setSelectedMarket}
                      onChange={() => onChange(selectedMarket)}
                      options={ancientOptions}
                      type={name}
                      z={2}
                    />
                  )}
                />
              </div>
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.market?.name?.message}
              </p>
              {selectedMarket.name !== "" && (
                <>
                  <label className="block mt-4 mb-2 text-sm font-medium text-textPrimary">
                    Options:
                  </label>
                  {fields.length < 2 && (
                    <p className="font-normal text-[14px] leading-5 text-textPrimary ml-2 mt-1">
                      {errors?.marketOptions?.message}
                    </p>
                  )}
                  <div>
                    {fields.map((item, index) => (
                      <div key={item.id} className="mb-4 first-of-type:mt-4">
                        <div className="flex justify-between gap-2 ">
                          <div className=" flex-1">
                            <input
                              type="text"
                              className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                              placeholder="Enter name"
                              {...register(`marketOptions.${index}.name`)}
                            />

                            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                              {errors?.marketOptions?.[index]?.name?.message}
                            </p>
                          </div>
                          <div className="flex-1">
                            <input
                              type="number"
                              className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
                              placeholder="Enter coefficient"
                              {...register(
                                `marketOptions.${index}.coefficient`
                              )}
                            />
                            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                              {
                                errors?.marketOptions?.[index]?.coefficient
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-end mt-1">
                          <button
                            className="w-full max-w-[20%] bg-transparent py-2 px-3 mb-4 text-s font-medium text-center text-danger border border-danger hover:ring-2 hover:ring-red-600 rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-red-600 focus:scale-115 hover:scale-[101%]"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    ))}

                    <button
                      type="button"
                      className="w-full max-w-[60%] self-center py-2 mt-2 px-3 text-s font-medium text-center text-white bg-primary rounded-lg hover:bg-[#3a57e2] hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#667ce8]"
                      onClick={() => {
                        append({
                          firstName: "appendBill",
                          lastName: "appendLuo",
                        });
                      }}
                    >
                      Add market option
                    </button>
                  </div>
                </>
              )}
            </>
          )}
          <div className="flex items-center my-4 ml-2">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
              {...register(`hidden`)}
            />
            <label
              htmlFor="default-checkbox"
              className="ml-2 text-sm font-medium text-textPrimary"
            >
              Hidden
            </label>
          </div>
        </form>
      </div>

      <div className="text-center mt-4">
        <button
          onClick={handleSubmit(onSubmit)}
          type="button"
          className="w-full bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default CreateMarket;
