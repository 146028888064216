import React from "react";
import { useAuthStore } from "../../stores/auth";
import { ReactComponent as AccountIcon } from "../../assets/account.svg";
const style = {
  wrapper: " bg-header h-16  flex items-center justify-between px-4 ",
  leftSide: "text-textPrimary",
  rightSide: "flex items-center gap-4 text-textPrimary",
};

const Header = () => {
  const { userDetail } = useAuthStore();

  return (
    <div>
      <div className={style.wrapper}>
        <div className={style.leftSide}></div>
        <div className={style.rightSide}>
          <h1>Welcome {userDetail?.name}!</h1>
          <AccountIcon />
          {/* <img
            className="w-10 h-10 rounded-full cursor-pointer"
            src={
              //  need to see if we fetch url from link or locally
              "https://picsum.photos/200"
            }
            alt="user avatar"
          /> */}
        </div>
      </div>
      <div className="border-b border-b-border ml-2"></div>
    </div>
  );
};

export default Header;
