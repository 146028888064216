import create from "zustand";
import jwt from "jwt-decode";

export const useAuthStore = create((set) => ({
  token: null,
  authErrors: null,
  userDetail: null,
  isLoading: false,
  isLoggedIn: false,

  getUserToken: () => {
    try {
      let tokenString = localStorage.getItem("grid-token");
      if (tokenString) {
        const tokenParsed = JSON.parse(tokenString);
        if (tokenParsed) {
          set({ token: tokenParsed });
          return [tokenParsed, jwt(tokenParsed).iat];
        }
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  getUserDetails: async (token) => {
    const userId = jwt(token).sub;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/user/${userId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      set({ userDetails: responseJson, isLoggedIn: true });
    } catch (error) {
      console.log("get user details: ", error);
    }
  },

  userLogin: async (email, password) => {
    try {
      set({ isLoading: true, authErrors: null });
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/auth/signin`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );

      if (!response.ok) {
        const responseJson = await response.json();
        if (responseJson.message === "USER.CREDENTIALS_INCORRECT")
          set({ authErrors: "Credentials incorrect" });
        throw Error(response.statusText);
      }
      const responseJson = await response.json();
      const { token, ...userData } = responseJson;
      set({
        userDetail: userData.user,
        token: token.access_token,
        isLoggedIn: true,
        isLoading: false,
      });
      window.localStorage.setItem(
        "grid-token",
        JSON.stringify(token.access_token)
      );
    } catch (error) {
      console.log(error);
      set({
        userDetail: null,
        isLoading: false,
        token: null,
        isLoggedIn: false,
      });
    }
  },

  clearAuthErrors: () => {
    set({ authErrors: null });
  },

  userLogout: () => {
    window.localStorage.removeItem("grid-token");
    set({
      userDetail: null,
      token: null,
      isLoggedIn: false,
    });
  },
}));
