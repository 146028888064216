import React from "react";

const style = {
  wrapper:
    "flex justify-between p-3 bg-[#000000] h-10 text-textPrimary border-t border-t-border",
};
const Footer = () => {
  return (
    <div className={style.wrapper}>
      <p>Powered by Grid</p>
      <p>Developed by MarrowLabs</p>
    </div>
  );
};

export default Footer;
