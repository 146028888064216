import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
import { useMatchStore } from "../../stores/match";

const sortOptions = [
  {
    id: "new",
    name: "Newest First",
  },
  {
    id: "old",
    name: "Oldest First",
  },
];

const Matches = () => {
  const { matches, loading, getMatches } = useMatchStore();
  const [searched, setSearched] = useState("");
  const [selectedSortFilter, setSelectedSortFilter] = useState("");

  const navigate = useNavigate();

  const getSortedData = (data, type) => {
    if (!data) {
      return [];
    }
    //newest first
    if (type === sortOptions[0].id) {
      return data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    // oldest first
    if (type === sortOptions[1].id) {
      return data.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    }
  };

  const displayData = useMemo(() => {
    let computedData = matches;
    if (!computedData) computedData = [];
    // filter by search
    if (searched && computedData.length > 0) {
      computedData = computedData.filter(
        (item) =>
          item.gridSeriesId.toString().includes(searched.toLowerCase()) ||
          item.game?.shortName.toLowerCase().includes(searched.toLowerCase()) ||
          item.tournament?.shortName
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.matchTeams[0]?.team.name
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.matchTeams[1]?.team.name
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item.format.toLowerCase().includes(searched.toLowerCase())
      );
    }
    // sorting
    if (selectedSortFilter) {
      computedData = getSortedData(computedData, selectedSortFilter);
    }
    return computedData;
  }, [searched, selectedSortFilter, matches]);

  const handleRowClick = (id) => {
    navigate(`/match/${id}`);
  };

  useEffect(() => {
    getMatches();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col">
      <h4 className="text-2xl text-white">MATCHES</h4>

      <div className="relative w-full max-w-[460px] my-4">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-600 dark:text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          className="bg-gray-800 border border-gray-600 text-white text-sm rounded-lg hover:border-primary focus:border-primary focus:outline-none block w-full pl-10 p-2.5 placeholder-gray-400 placeholder-opacity-20"
          placeholder="Search..."
          value={searched}
          onChange={(e) => setSearched(e.target.value)}
        />
      </div>

      <div className="overflow-scroll max-h-[600px] max-w-max rounded-lg scrollbar-hide my-8">
        <table className="text-sm text-left text-textPrimary w-full overflow-scroll">
          <thead className="text-xs text-textPrimary uppercase bg-[#111419]">
            <tr>
              <th scope="col" className="py-3 px-6">
                Feed id
              </th>
              <th scope="col" className="flex gap-2 items-center py-3 px-6">
                Scheduled start time
                <AiFillCaretDown
                  onClick={() => {
                    selectedSortFilter === "new"
                      ? setSelectedSortFilter("old")
                      : setSelectedSortFilter("new");
                  }}
                  className={`${
                    selectedSortFilter === "old"
                      ? "fill-slate-400 rotate-180"
                      : selectedSortFilter === "new" && "fill-slate-400"
                  } w-[16px] h-[16px]`}
                />
              </th>
              <th scope="col" className="py-3 px-6">
                Game
              </th>
              <th scope="col" className="py-3 px-6">
                Tournament
              </th>
              <th scope="col" className="py-3 px-6">
                Team 1 name
              </th>
              <th scope="col" className="py-3 px-6">
                Team 2 name
              </th>
              <th scope="col" className="py-3 px-6">
                Best of
              </th>
              <th scope="col" className="py-3 px-6">
                Current game
              </th>
            </tr>
          </thead>

          <tbody>
            {displayData?.length > 0 &&
              displayData.map((match) => {
                return (
                  <tr
                    className="bg-body hover:rounded-3xl hover:bg-[#242934] cursor-pointer"
                    key={match.id}
                    onClick={() => handleRowClick(match.id)}
                  >
                    <td className="py-4 px-6">{match.gridSeriesId}</td>

                    <th
                      scope="row"
                      className="py-4 px-6 font-medium text-white"
                    >
                      {match.scheduledStartTime.slice(0, 10)},{" "}
                      {match.scheduledStartTime.slice(11, 19)}
                    </th>
                    <td className="py-4 px-6">
                      {match?.game.shortName.charAt(0).toUpperCase() +
                        match?.game.shortName.slice(1)}
                    </td>
                    <td className="py-4 px-6">{match.tournament.shortName}</td>
                    <td className="py-4 px-6">
                      {match?.matchTeams[0]?.team.name}
                    </td>
                    <td className="py-4 px-6">
                      {match?.matchTeams[1]?.team.name}
                    </td>
                    <td className="py-4 px-6">{match?.format}</td>
                    <td className="py-4 px-6">
                      {match.currentGame === 0
                        ? "Not started"
                        : `game ${match.currentGame}`}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {displayData?.length === 0 && !loading && (
          <p className="mt-4 text-lg text-textPrimary text-center">
            There are currently no matches
          </p>
        )}
      </div>
    </div>
  );
};

export default Matches;
