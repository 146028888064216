import React from "react";

const BoosterButton = ({
  active,
  status,
  setWinBoosters,
  booster,
  hero,
  disabled,
}) => {
  return (
    <div>
      <button
        disabled={disabled}
        className={`text-gray-400 text-sm border border-primary px-4 py-2 rounded-xl hover:text-white hover:ring-2 hover:ring-blue-400 active:not(:disabled):bg-primary disabled:cursor-not-allowed disabled:bg-none disabled:border-gray-500 disabled:hover:ring-0 disabled:hover:text-gray-400 ${
          (active || status === 1) && "bg-primary !text-white"
        }`}
        onClick={() =>
          setWinBoosters((prev) => {
            if (prev.some((item) => item.id === booster.id)) {
              return prev.filter((item) => item.id !== booster.id);
            } else if (prev.some((item) => item.heroId === hero.hero.id)) {
              return [
                ...prev.filter((item) => item.heroId !== hero.hero.id),
                {
                  id: booster.id,
                  coefficient: booster.coefficient,
                  heroId: hero.hero.id,
                  name: booster.name,
                },
              ];
            } else {
              return [
                ...prev,
                {
                  id: booster.id,
                  coefficient: booster.coefficient,
                  heroId: hero.hero.id,
                  name: booster.name,
                },
              ];
            }
          })
        }
      >
        {booster.name} - {booster.coefficient}
      </button>
    </div>
  );
};

export default BoosterButton;
