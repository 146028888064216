import React, { useState } from "react";
import { DASHBOARD_SIDEBAR_LINKS } from "../lib/constants/navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuthStore } from "../../stores/auth";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import { ReactComponent as TextLogoIcon } from "../../assets/text-logo.svg";

const style = {
  wrapper: "h-screen p-3 bg-body text-white duration-300 relative",
  topPart: "py-8 flex flex-col gap-0.5",
  bottomPart: "border-t border-t-border py-1",
  logoContainer: "inline-flex items-center px-1 py-3 cursor-pointer",
  logoName: "text-neutral-100 text-lg origin-left font-medium duration-300",
  logout:
    "flex items-center flex gap-4 font-light px-2 py-2 hover:bg-[#0f1015] hover:rounded-lg hover:text-[#a70000] hover:no-underline rounded-sm text-textPrimary cursor-pointer",
};

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();
  const { userLogout } = useAuthStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    userLogout();
    navigate("/login");
  };

  return (
    <div
      className={`flex flex-col bg-body p-2 pt-0.5 duration-300 relative ${
        open ? "w-72" : "w-20"
      }`}
    >
      <button
        type="button"
        onClick={() => setOpen((prev) => !prev)}
        className={`absolute -right-4 top-3.5 text-primary border border-primary hover:bg-primary hover:text-white focus:ring-1 focus:outline-none focus:ring-[#667ce8] font-small rounded-full text-xs p-2 text-center inline-flex items-center ${
          !open && "rotate-180 duration-300"
        }`}
      >
        <svg
          aria-hidden="true"
          className="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </button>
      <div className={style.logoContainer} onClick={() => navigate("/")}>
        <div className="mr-2">
          <LogoIcon
            className={` text-2xl rounded  block float-left duration-500 ${
              open && "rotate-[360deg]"
            }`}
            width={36}
            height={40}
          />
        </div>
        <span
          className={`text-neutral-100 origin-left font-medium text-2xl duration-300 ${
            !open && "scale-0"
          }`}
        >
          <TextLogoIcon
            className={` text-2xl rounded  block float-left duration-500 ${
              open && "rotate-[360deg]"
            }`}
            width={80}
            height={40}
          />
        </span>
      </div>
      <ul className="pt-2 flex-1">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <li key={link.key}>
            <Link
              to={link.path}
              className={`${
                pathname === link.path ? "text-white" : "text-textPrimary"
              } flex items-center gap-x-4 cursor-pointer p-2 hover:bg-[#0f1015] rounded-md mt-2`}
            >
              {link.icon}
              <span
                className={`text-base font-medium flex-1 ${!open && "hidden"}`}
              >
                {link.label}
              </span>
            </Link>
          </li>
        ))}
      </ul>
      <div className={style.bottomPart}>
        <div className={style.logout} onClick={handleLogout}>
          <span className="text-2xl">
            <AiOutlineLogout />
          </span>
          <span className={`m-0 p-0 ${!open && "hidden"}`}>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
