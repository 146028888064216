import React from "react";

const Modal = ({ visible, onClose, data }) => {
  const handleOnClose = (e) => {
    if (e.target.id === "closeIt") {
      onClose();
    }
  };

  const handleSuspendMarket = () => {
    data.action();
  };

  if (!visible) return null;
  return (
    <div
      className=" fixed inset-0 bg-black bg-opacity-25 backdrop-blur-[1px] flex items-center justify-center"
      id="closeIt"
      onClick={handleOnClose}
    >
      <div className="bg-body p-8 rounded-2xl shadow-2xl w-full max-w-[400px] mx-4">
        <svg
          aria-hidden="true"
          className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          ></path>
        </svg>
        <div className="p-6 text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to {data.actionString} this game?
          </h3>
          <div className="flex text-center mt-4 gap-2">
            <button
              onClick={handleSuspendMarket}
              data-modal-toggle="popup-modal"
              type="button"
              className="flex-1 self-center py-2 px-3 text-s font-medium text-center text-white bg-primary rounded-lg hover:bg-[#3a57e2] hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#667ce8]"
            >
              Yes, I'm sure
            </button>
            <button
              data-modal-toggle="popup-modal"
              type="button"
              className="flex-1 bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
              onClick={onClose}
            >
              No, cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
