import { useEffect, useRef, useState } from "react";
import { useAuthStore } from "../../../stores/auth";
import { useMatchStore } from "../../../stores/match";
import Games from "./Games";
import StopWatch from "./Stopwatch";

const GameController = ({ matchId }) => {
  const [time, setTime] = useState(null);
  const [timeId, setTimeId] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const { token } = useAuthStore();
  const { controlMatch, getMatch, match } = useMatchStore();
  const [gameId, setGameId] = useState(match.currentGame);
  const [isPaused, setIsPaused] = useState(false);
  const [edit, setEdit] = useState(false);
  const inputEditTimeRef = useRef("");
  const [areYouSureVisible, setAreYouSureVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const timeObj = JSON.parse(localStorage.getItem(`stopwatch-${matchId}`));
    if (timeObj) {
      if (!timeObj.active) {
        setTime(timeObj.clock);
        setTimeId(0);
      } else {
        setIsActive(true);
        if (timeObj.stopped) {
          setTime(timeObj.clock);
          setIsPaused(true);
          if (timeObj.clock < 0) {
            setTimeId(0);
          } else {
            let minutes = Math.floor(timeObj.clock / 60000);
            setTimeId(Math.floor(minutes / 5) + 1);
          }
        } else {
          const nt = Date.now() - timeObj.timestamp + timeObj.clock;
          if (nt < 0) {
            setTimeId(0);
          } else {
            let minutes = Math.floor(nt / 60000);
            setTimeId(Math.floor(minutes / 5) + 1);
          }
          setTime(nt);
          setIsActive(true);
          setIsPaused(false);
        }
      }
    } else {
      setTime(-90000);
    }
  }, [matchId]);

  useEffect(() => {
    if (time !== null && time % 300000 === 0) {
      setTimeId(timeId + 1);
    }
    //eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    let interval = null;
    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 200);
      }, 200);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isPaused]);

  useEffect(() => {
    if (isActive) {
      handleControl();
    }
    //eslint-disable-next-line
  }, [timeId, gameId, isActive]);

  const handleCloseModal = () => {
    setAreYouSureVisible(false);
    setModalData({});
  };

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
    setGameId((prev) => (prev !== 5 ? prev + 1 : prev));
    handleCloseModal();
    const timeStr = JSON.stringify({
      clock: time,
      timestamp: Date.now(),
      stopped: false,
      active: true,
    });
    localStorage.setItem(`stopwatch-${matchId}`, timeStr);
  };

  const handlePauseResume = () => {
    if (!isPaused) {
      setIsPaused(true);
      const timeStr = JSON.stringify({
        clock: time,
        timestamp: Date.now(),
        stopped: true,
        active: true,
      });
      localStorage.setItem(`stopwatch-${matchId}`, timeStr);
    } else {
      setIsPaused(false);
      const timeStr = JSON.stringify({
        clock: time,
        timestamp: Date.now(),
        stopped: false,
        active: true,
      });
      localStorage.setItem(`stopwatch-${matchId}`, timeStr);
    }
  };

  const handleReset = () => {
    setIsActive(false);
    setIsPaused(true);
    setTime(-90000);
    setTimeId(0);
    handleCloseModal();
    localStorage.removeItem(`stopwatch-${matchId}`);
  };

  const handleEdit = () => {
    const splitArray = inputEditTimeRef.current.value.split(":");
    let time = 0;
    splitArray[0] = parseInt(splitArray[0]) * 60000;

    if (inputEditTimeRef.current.value.startsWith("-")) {
      splitArray[1] = parseInt(splitArray[1]) * -1000;
      time = parseInt(splitArray[0]) + parseInt(splitArray[1]);
      setTime(time);
      const timeStr = JSON.stringify({
        clock: time,
        timestamp: Date.now(),
        stopped: isPaused,
      });
      localStorage.setItem(`stopwatch-${matchId}`, timeStr);
    } else {
      splitArray[1] = parseInt(splitArray[1]) * 1000;
      time = parseInt(splitArray[0]) + parseInt(splitArray[1]);
      setTime(time);
      const timeStr = JSON.stringify({
        clock: time,
        timestamp: Date.now(),
        stopped: isPaused,
      });
      localStorage.setItem(`stopwatch-${matchId}`, timeStr);
    }
    let minutes = Math.floor(time / 60000);
    if (inputEditTimeRef.current.value.startsWith("-")) {
      setTimeId(0);
    } else {
      setTimeId(Math.floor(minutes / 5) + 1);
    }
    inputEditTimeRef.current.value = "";
    setEdit(false);
  };

  const handleControl = async () => {
    if (token) {
      const response = await controlMatch(match.id, token, gameId, timeId);
      if (response.ok) {
        getMatch(match.id);
      }
    }
  };

  return (
    <div className="flex gap-6">
      <StopWatch
        time={time}
        isActive={isActive}
        isPaused={isPaused}
        setIsActive={setIsActive}
        edit={edit}
        setEdit={setEdit}
        handlePauseResume={handlePauseResume}
        handleReset={handleReset}
        handleEdit={handleEdit}
        handleStart={handleStart}
        handleCloseModal={handleCloseModal}
        inputEditTimeRef={inputEditTimeRef}
        areYouSureVisible={areYouSureVisible}
        setAreYouSureVisible={setAreYouSureVisible}
        modalData={modalData}
        setModalData={setModalData}
        gameId={gameId}
      />
      <Games timeId={timeId} defaultGame={match.currentGame} gameId={gameId} />
    </div>
  );
};

export default GameController;
