import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { automatizeMarketSchema } from "../../components/shared/schemas/schemaAutomatizeMarket";
import { useMatchStore } from "../../stores/match";

const AutomatizeMarkets = ({ token, matchId }) => {
  const { getMatch } = useMatchStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(automatizeMarketSchema),
    defaultValues: {
      gameKillHandicap: "",
      gameKillTotal: "",
    },
  });

  const onSubmit = async (data) => {
    let response = undefined;
    const { gameKillHandicap, gameKillTotal } = data;

    try {
      response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/calculations/tvt/${matchId}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            gameKillHandicap: parseFloat(gameKillHandicap),
          }),
        }
      );
      if (response.ok) {
        response = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/calculations/pvp/${matchId}`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              gameKillHandicap: parseFloat(gameKillHandicap),
              gameKillTotal: parseFloat(gameKillTotal),
            }),
          }
        );
        if (response.ok) {
          reset();
          getMatch(matchId);
        }
      }
    } catch (error) {
      console.log("Error automatizing market", error);
    }
  };

  return (
    <div className=" bg-body p-8 rounded-2xl shadow-2xl w-full max-w-[460px] h-fit">
      <h1 className="font-semibold text-center text-2xl text-white mb-5 border-b border-gray-200 border-opacity-10 pb-4">
        Automate Match Markets
      </h1>

      <div className="flex flex-col">
        <form>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-textPrimary">
              Game Kill Handicap
            </label>
            <input
              type="number"
              className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
              placeholder="Enter Game Kill Handicap"
              {...register("gameKillHandicap")}
            />
            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
              {errors.gameKillHandicap?.message}
            </p>
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-textPrimary">
              Game Kill Total
            </label>
            <input
              type="number"
              className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
              placeholder="Enter Game Kill Handicap"
              {...register("gameKillTotal")}
            />
            <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
              {errors.gameKillTotal?.message}
            </p>
          </div>
        </form>
      </div>
      <div className="text-center mt-4">
        <button
          onClick={handleSubmit(onSubmit)}
          type="button"
          className="w-full bg-transparent py-2 px-3 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4]"
        >
          Automatize
        </button>
      </div>
    </div>
  );
};

export default AutomatizeMarkets;
