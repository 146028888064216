import * as yup from "yup";

export const addNewsSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter a name")
    .test("len", "Must be at least 2 characters", (val) => val.length >= 2),
  description: yup
    .string()
    .required("Please enter a description")
    .test("len", "Must be at least 2 characters", (val) => val.length >= 2),
});
