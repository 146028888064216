import { useEffect, useState } from "react";
import { useAuthStore } from "../../stores/auth";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteUser from "./deleteUser";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [areYouSureVisible, setAreYouSureVisible] = useState(false);
  const { token } = useAuthStore();

  const handleDeleteUserClose = () => {
    setUserId(null);
    setAreYouSureVisible(false);
  };

  const getUserId = (id) => {
    setUserId(id);
  };

  const getUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/user`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.ok) {
        setUsers(data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Get news:", error);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/user/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        handleDeleteUserClose();
        getUsers();
      }
    } catch (error) {
      console.log("Error deleting user: ", error);
    }
  };

  useEffect(() => {
    getUsers();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="max-w-[800px]">
      <h4 className="text-2xl text-white">USERS</h4>

      <div className="overflow-scroll max-h-[600px] rounded-lg scrollbar-hide my-8">
        <table className="text-sm text-left text-textPrimary w-full overflow-scroll">
          <thead className="text-xs text-textPrimary uppercase bg-[#111419]">
            <tr>
              <th scope="col" className="py-3 px-6">
                id
              </th>
              <th scope="col" className="py-3 px-6">
                Name
              </th>
              <th scope="col" className="py-3 px-6">
                Level
              </th>
              <th scope="col" className="py-3 px-6">
                Rank
              </th>
              <th scope="col" className="py-3 px-6">
                Buffs
              </th>
              <th scope="col" className="py-3 px-6">
                Role
              </th>
              <th scope="col" className="py-3 px-6">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {users?.length > 0 &&
              users.map((user) => {
                return (
                  <tr
                    className="bg-body hover:rounded-3xl hover:bg-[#242934] cursor-pointer"
                    key={user.id}
                  >
                    <th className="py-4 px-6 text-white">{user.id}</th>
                    <th className="py-4 px-6 text-white">{user.name}</th>
                    <td className="py-4 px-6">{user.level}</td>
                    <td className="py-4 px-6">{user.rank}</td>
                    <td className="py-4 px-6">{user.buffs}</td>
                    <td className="py-4 px-6">{user.role}</td>
                    <td className="py-3 px-6">
                      <div className="flex">
                        <AiOutlineDelete
                          onClick={() => {
                            getUserId(user.id);
                            setAreYouSureVisible(true);
                          }}
                          className="text-lg hover:cursor-pointer hover:fill-primary flex-1"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {users?.length === 0 && !loading && (
          <p className="mt-4 text-lg text-textPrimary text-center">
            There are currently no users
          </p>
        )}
      </div>
      {areYouSureVisible && userId && (
        <DeleteUser
          onClose={handleDeleteUserClose}
          visible={areYouSureVisible}
          handleAction={handleDeleteUser}
          data={userId}
        />
      )}
    </div>
  );
};

export default Users;
