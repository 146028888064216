import { GiDaggers } from "react-icons/gi";
import { BiNews } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
export const DASHBOARD_SIDEBAR_LINKS = [
  // {
  //   key: "series",
  //   label: "Series",
  //   path: "/series",
  //   icon: <GiAbstract031 className="text-2xl block float-left" />,
  // },
  {
    key: "matches",
    label: "Matches",
    path: "/matches",
    icon: <GiDaggers className="text-2xl block float-left" />,
  },
  {
    key: "users",
    label: "Users",
    path: "/users",
    icon: <FiUsers className="text-2xl block float-left" />,
  },
  {
    key: "news",
    label: "News",
    path: "/news",
    icon: <BiNews className="text-2xl block float-left" />,
  },
];

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
  {
    key: "settings",
    label: "settings",
    path: "/settings",
    icon: "",
  },
  {
    key: "support",
    label: "Support",
    path: "/support",
    icon: "",
  },
];
