import { loginSchema } from "../../components/shared/schemas/schemaLogin";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useAuthStore } from "../../stores/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthStore();
  const { isLoading, userLogin, authErrors, clearAuthErrors } = useAuthStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: { email: "", password: "" },
  });

  const onSubmit = (data) => {
    const { email, password } = data;
    userLogin(email, password);
    reset();
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/matches");
    }
    //eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <div className="bg-body flex justify-center items-center h-screen">
      <div className="flex-1 max-w-[400px] h-fit">
        <div className="bg-[#15181f] p-8 rounded-xl w-full shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]">
          <h4 className="text-2xl font-semibold text-gray-300 mb-6">
            Sign in to your account
          </h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-textPrimary">
                Email
              </label>
              <input
                type="text"
                className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-[#556ee6] focus:border-[#556ee6] focus:outline-none"
                placeholder="Enter Email"
                {...register("email")}
                onClick={authErrors ? clearAuthErrors : null}
              />
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.email?.message}
              </p>
            </div>
            <div className="mb-4">
              <label
                htmlFor="select"
                className="block mb-2 text-sm font-medium text-textPrimary"
              >
                Password
              </label>
              <input
                type="password"
                className="border text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-[#556ee6] focus:border-[#556ee6] focus:outline-none"
                placeholder="Enter Password"
                {...register("password")}
                onClick={authErrors ? clearAuthErrors : null}
              />
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {errors.password?.message}
              </p>
              <p className="font-normal text-[11px] leading-5 text-danger ml-2 mt-1">
                {authErrors !== "" && authErrors}
              </p>
            </div>
            <div className="w-full text-center mt-6">
              <button
                disabled={isLoading}
                type="submit"
                className="w-full py-2 px-3 text-s font-medium text-center text-white bg-primary rounded-lg hover:bg-[#3a57e2] hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#667ce8]"
              >
                {isLoading ? "Loading" : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
