import { BiPlay, BiPause, BiReset } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";
import Modal from "./Modal";

function StopWatch({
  time,
  isActive,
  isPaused,
  edit,
  setEdit,
  handlePauseResume,
  handleReset,
  handleEdit,
  handleStart,
  handleCloseModal,
  inputEditTimeRef,
  areYouSureVisible,
  setAreYouSureVisible,
  modalData,
  setModalData,
  gameId,
}) {
  return (
    <div className="text-white flex flex-col items-center min-w-[200px] min-h-[140px] bg-body rounded-xl p-6">
      <div className="flex justify-center items-center text-3xl">
        {time >= 0 && (
          <>
            <div className="min-w-[80px] mx-1 p-2 bg-[#3a3e50] text-primary text-center rounded-lg">
              {time !== null
                ? ("0" + Math.floor((time / 60000) % 60)).slice(-2)
                : "-"}
            </div>
            <div className="min-w-[80px] mx-1 p-2 bg-[#3a3e50] text-primary text-center rounded-lg">
              {time !== null
                ? ("0" + Math.floor((time / 1000) % 60)).slice(-2)
                : "-"}
            </div>
          </>
        )}
        {time < 0 && (
          <>
            <div className="min-w-[80px] mx-1 p-2 bg-[#3a3e50] text-primary text-center rounded-lg">
              {time !== null
                ? "- " + ("0" + Math.floor((-time / 60000) % 60)).slice(-2)
                : "-"}
            </div>
            <div className="min-w-[80px] mx-1 p-2 bg-[#3a3e50] text-primary text-center rounded-lg">
              {time !== null
                ? ("0" + Math.ceil((-time / 1000) % 60)).slice(-2)
                : "-"}
            </div>
          </>
        )}
      </div>

      <div className="mt-4">
        <div className="flex flex-col items-center gap-2">
          {isActive ? (
            <button
              disabled={gameId === 5}
              onClick={handlePauseResume}
              type="button"
              className="self-center text-primary border border-primary hover:bg-primary hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-primary disabled:opacity-70"
            >
              {isPaused ? (
                <BiPlay className="text-2xl" />
              ) : (
                <BiPause className="text-2xl" />
              )}
            </button>
          ) : (
            <button
              disabled={gameId === 5}
              onClick={() => {
                setModalData({
                  actionString: "start",
                  action: handleStart,
                });
                setAreYouSureVisible(true);
              }}
              type="button"
              className="self-center text-primary border border-primary hover:bg-primary hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-primary disabled:opacity-70"
            >
              <BiPlay className="text-2xl" />
            </button>
          )}
          <button
            disabled={gameId === 5}
            onClick={() => {
              setModalData({ actionString: "reset", action: handleReset });
              setAreYouSureVisible(true);
            }}
            type="button"
            className="text-primary border border-primary hover:bg-primary hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-primary disabled:opacity-70"
          >
            <BiReset className="text-2xl" />
          </button>
          <button
            disabled={gameId === 5}
            onClick={() => {
              setEdit(true);
            }}
            type="button"
            className="self-center text-primary border border-primary hover:bg-primary hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-700 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center disabled:cursor-not-allowed disabled:hover:bg-transparent disabled:hover:text-primary disabled:opacity-70"
          >
            <AiOutlineEdit className="text-2xl" />
          </button>
        </div>
      </div>

      {edit && (
        <div className="flex flex-col mt-4">
          <input
            type="text"
            className="border text-sm rounded-lg block w-[150px] p-2.5 bg-gray-800 border-gray-600 placeholder-gray-400 placeholder-opacity-20 text-white hover:border-primary focus:border-primary focus:outline-none"
            placeholder="1:30"
            ref={inputEditTimeRef}
          />
          <div className="flex items-center gap-2 w-full mt-2">
            <button
              onClick={handleEdit}
              type="button"
              className="flex-1 bg-transparent p-2 text-s font-medium text-center text-primary border border-primary hover:bg-primary hover:text-white bg-primary rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-[#4762e4] disabled:cursor-not-allowed"
            >
              Edit
            </button>
            <button
              onClick={() => setEdit(false)}
              type="button"
              className="flex-1 bg-transparent p-2 text-s font-medium text-center text-danger border border-danger hover:ring-2 hover:ring-red-600 rounded-lg hover:shadow-xl focus:ring-2 focus:outline-none focus:ring-red-600 focus:scale-115 hover:scale-[101%]"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {areYouSureVisible && modalData && (
        <Modal
          onClose={handleCloseModal}
          visible={areYouSureVisible}
          data={modalData}
        />
      )}
    </div>
  );
}

export default StopWatch;
