import create from "zustand";

export const useMatchStore = create((set) => ({
  match: null,
  loading: true,
  matches: null,
  clearMatchDetails: () => {
    set({
      match: null,
      matches: null,
    });
  },

  getMatches: async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/match`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );
      let data = await response.json();

      if (response.ok) {
        set({
          matches: data,
        });
      }
      set({
        loading: false,
      });
    } catch (error) {
      console.log("Get matches:", error);
      set({
        loading: false,
      });
    }
  },

  getMatch: async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/match/${id}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      );
      let data = await response.json();
      if (response.ok) {
        set({
          match: data,
        });
      }
    } catch (error) {
      console.log("Get match:", error);
    }
  },

  updateMatch: async (id, token, action) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/match/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            suspendBoosters: action,
          }),
        }
      );

      if (response) return response;
    } catch (error) {
      console.log("Update match:", error);
    }
  },

  controlMatch: async (id, token, currentGame, intervalId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/match/control/${id}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            currentGame: currentGame,
            intervalId: intervalId,
          }),
        }
      );

      if (response) return response;
    } catch (error) {
      console.log("Control match:", error);
    }
  },
}));
