import { useState } from "react";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./components/shared/layout";

import Login from "./pages/auth/Login";
import Matches from "./pages/matches";

import { useAuthStore } from "./stores/auth";
import MatchDetails from "./pages/match-details";
import { ToastContainer } from "react-toastify";
import News from "./pages/news";
import Users from "./pages/users";

const App = () => {
  const { getUserToken, userLogout, getUserDetails } = useAuthStore();
  const [checkedUser, setCheckedUser] = useState(false);

  useEffect(() => {
    let logoutInterval;
    const token = getUserToken();

    if (token) {
      (async () => {
        // token valid for 23.5 hours (almost 24 hours)
        let tokenValidityInterval = 1000 * 60 * 60 * 23.5;
        let expirationTime =
          token[1] * 1000 + tokenValidityInterval - Date.now();
        if (expirationTime > 0) {
          // if token still valid get userdata and do logout in calculated expiration time
          await getUserDetails(token[0]);
          logoutInterval = setTimeout(() => {
            userLogout();
          }, expirationTime);
        } else {
          // if token in local storage but outdated
          userLogout();
        }
        setCheckedUser(true);
      })();
    } else {
      setCheckedUser(true);
    }

    return () => {
      clearInterval(logoutInterval);
    };
  }, [getUserToken, getUserDetails, userLogout]);

  if (!checkedUser) return null;

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index path="matches" element={<Matches />} />
          <Route path="match/:id" element={<MatchDetails />} />
          <Route path="users" element={<Users />} />
          <Route path="news" element={<News />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
